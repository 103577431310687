<template>
  <div class="main" style="width: 100%;min-height: 100vh;">
    <a-row type="flex" justify="center" align="top" style="height:100%">
      <a-col class="login-left" :xs="{ span: 0 }" :lg="{ span: 14 }"></a-col>
      <a-col class="login-right" :xs="{ span: 24 }" :lg="{ span: 10 }">
        <div class="login-right-con">
          <div class="login-right-logo">
            <logo-svg class="logo" style="width: 40px;" />
            <!--title-svg class="title" /-->
            <span style="color: #1890ff; font-size: 26px; font-weight: bold">乡居假日智慧社区网格化管理</span>
          </div>
          <a-form
            id="formLogin"
            class="login-form"
            ref="formLogin"
            :form="form"
            @submit="handleSubmit(1,$event)"
          >
            <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" :message="loginMessageErr" />
            <a-form-item>
              <a-input
                size="large"
                type="text"
                placeholder="请输入用户名或手机号"
                v-decorator="['username', {rules: [{ required: true, message: '请输入用户名或手机号' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}]"
              >
                <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-input-password
                size="large"
                placeholder="请输入密码"
                v-decorator="['password', {rules: [{ required: true, message: $t('user.password.required') }], validateTrigger: 'blur'}]"
              >
                <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </a-input-password>
            </a-form-item>

            <!--a-form-item>
              <a-checkbox v-decorator="['rememberMe', { valuePropName: 'checked' }]">{{ $t('user.login.remember-me') }}</a-checkbox>
            </a-form-item-->

            <a-form-item style="margin-top:24px">
              <a-button
                size="large"
                type="primary"
                htmlType="submit"
                class="login-form-button"
                :loading="state.loginBtn"
                :disabled="state.loginBtn"
              >{{ $t('user.login.login') }}</a-button>
            </a-form-item>
          </a-form>
          <div class="login-copyright">版权所有 © 大道简器信息咨询（唐山）有限公司，保留所有权利。</div>
        </div>
      </a-col>
    </a-row>
    <a-drawer
      width="300"
      placement="right"
      @close="closeTeam"
      :closable="true"
      :visible="visible"
      :drawer-style="{ position: 'absolute' }"
      style="position: absolute"
    >
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline" class="user-layout-login">
            <h3 class="setting-drawer-index-title">选择角色</h3>
            <a-form-item>
              <a-select placeholder="请选择角色" v-model="userRoleId" style="width: 200px;">
                <a-select-option :value="0">请选择角色</a-select-option>
                <a-select-option v-for="item in userTeamList" :key="item.roleId" :value="item.roleId">
                  {{ item.applyName }}--{{ item.roleName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button class="login-button" @click="switchTeam" type="primary">确认</a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-card>
    </a-drawer>
  </div>
</template>
<script>
// import md5 from 'md5'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getSmsCaptcha, get2step, teamList } from '@/api/login'
import LogoSvg from '../../assets/logo.svg?inline'
import TitleSvg from '../../assets/title.svg?inline'

export default {
  components: {
    LogoSvg,
    TitleSvg
  },
  data () {
    return {
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      visible: false,
      userTeamList: [],
      userRoleId: 0,
      loginMessageErr: '账户或密码错误'
    }
  },
  created () {
    get2step({ })
      .then(res => {
        this.requiredTwoStepCaptcha = res.result.stepCode
      })
      .catch(() => {
        this.requiredTwoStepCaptcha = false
      })
    // this.requiredTwoStepCaptcha = true
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // handler
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleTabClick (key) {
      this.customActiveKey = key
      // this.form.resetFields()
    },
    // 切换团队
    switchTeam (e) {
      if (this.userRoleId <= 0) {
        this.$message.error('请选择角色')
        return false
      }
      console.log(e, this.userRoleId)
      this.visible = false
      this.handleSubmit(2, e)
    },
    closeTeam () {
      console.log('closeTeam')
      this.visible = false
    },
    handleSubmit (loginType, e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        Login
      } = this
      console.log(loginType)
      state.loginBtn = true
      const _this = this
      const validateFieldsKey = ['username', 'password']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          console.log('login form', values)
          const loginParams = { ...values }
          delete loginParams.username
          loginParams[!state.loginType ? 'email' : 'username'] = values.username
          loginParams.password = values.password
          console.log(loginType)
          if (loginType === 1) {
            teamList(loginParams).then((res) => {
              console.log('teamList', res)
              if (res.errorCode === 0) {
                _this.userTeamList = res.result
                _this.userRoleId = _this.userTeamList[0]['roleId']
                if (_this.userTeamList.length > 1) {
                  _this.visible = true
                } else {
                  _this.visible = false
                  _this.handleSubmit(2, e)
                }
              } else {
                state.loginBtn = false
                _this.requestFailed(res)
              }
            }).catch(err => this.requestFailed(err)).finally(() => {
              state.loginBtn = false
            })
          } else {
            loginParams.roleId = _this.userRoleId
            Login(loginParams).then((res) => {
              if (res.errorCode === 0) {
                console.log('loginSuccess')
                _this.loginSuccess(res)
              } else {
                _this.loginMessageErr = res.errorMsg
                state.loginBtn = false
                _this.requestFailed(res)
              }
            })
            /* Login(loginParams)
              .then((res) => this.loginSuccess(res))
              .catch(err => this.requestFailed(err))
              .finally(() => {
                state.loginBtn = false
              }) */
          }
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          getSmsCaptcha({ mobile: values.mobile }).then(res => {
            setTimeout(hide, 2500)
            this.$notification['success']({
              message: '提示',
              description: '验证码获取成功，您的验证码为：' + res.result.captcha,
              duration: 8
            })
          }).catch(err => {
            setTimeout(hide, 1)
            clearInterval(interval)
            state.time = 60
            state.smsSendBtn = false
            this.requestFailed(err)
          })
        }
      })
    },
    stepCaptchaSuccess () {
      this.loginSuccess()
    },
    stepCaptchaCancel () {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess (res) {
      console.log('loginSuccess', res)
      // check res.homePage define, set $router.push name res.homePage
      // Why not enter onComplete
      /* this.$router.push({ path: '/dashboard/workplace' }, () => {
        console.log('onComplete')
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
        this.state.loginBtn = false
      }) */
      this.$router.push({ path: '/dashboard/workplace' })
      // 延迟 1 秒显示欢迎信息
      this.isLoginError = false
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      setTimeout(() => {
        this.state.loginBtn = false
      }, 2000)
    },
    requestFailed (err) {
      console.log(err)
      this.isLoginError = true
      this.$notification['error']({
        message: '错误',
        description: err.errorMsg || err.message || '请求出现错误，请稍后再试',
        duration: 4
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  min-height: 100vh;
}
.agreement{
  span{
    text-decoration: underline;
    cursor: pointer;
  }
}
.agree-modal{
  /deep/.ant-modal-header{
    border: none;
  }
  /deep/.ant-modal-footer{
    text-align: center;
    .ant-btn{
      width: 200px;
    }
  }
  /deep/.ant-modal-footer:after{
    height: 0;
  }
  .agree-box{
    height: 400px;
    overflow: auto;
    padding: 0 32px;
    .p-box{
      padding-top: 24px;
    }
  }
}
.login-left {
  position: relative;
  height: 100vh;
  min-height: 700px;
  background: url(/bg.png) no-repeat 50%;
  background-size: cover;
  .login-left-img {
    max-width: 100%;
    max-height: 100%;
  }
  .login-left-logo {
    position:absolute;
    left:54px;
    bottom:54px;
    height: 64px;
    // width:25%;
    display: flex;
    align-items: center;
    svg.logo {
      width: 64px;
      height: 64px;
      vertical-align: middle;
    }
    svg.title {
      color: #FFFFFF;
      width: 88px;
      height: 28px;
      margin-left: 8px;
    }
  }
}

.login-right {
  height: 100vh;
  min-height: 700px;
  .login-right-con {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 60px;
  }
  .login-right-logo {
    height: 72px;
    margin: 107px auto 36px auto;
    display: flex;
    align-items: center;
    svg.logo {
      width: 72px;
      height: 72px;
      vertical-align: middle;
    }
    svg.title {
      width: 98px;
      height: 30px;
      margin-left: 10px;
    }
  }
}
/deep/.ant-drawer-body{
  color:#262626;
  padding: 48px;
  .anticon{
    font-size: 18px;
    color: #BFBFBF;
  }
  .back{
    width: 50px;
    padding: 16px 16px 16px 0;
    &:hover{
      cursor: pointer;
    }
  }
  .title{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 8px 0 48px 0;
  }
  .team-list{
    width: 100%;
    .team-item{
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:hover{
        cursor: pointer;
      }
      &.selected{
        border: 1px solid #1890FF;
      }
      img{
        //width: 108px;
        height: 44px;
        margin-right: 16px;
      }
      span{
        flex: 1;
        font-size: 14px;
        line-height: 22px;
        margin-right: 20px;
      }
      .anticon{
        color:#333333;
      }
    }
  }
}
.login-form {
  width: 320px;
  text-align: left;
  .login-form-button {
    width: 100%;
  }
  .form-item-con {
    position: relative;
    width: 100%;
    height: 100%;
    .btn-captcha {
      position: absolute;
      top: -8px;
      right: 0;
    }
  }
}
.login-copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #BDBDBD;
  margin-bottom: 40px;
}
.placeholder {
  height: 64px;
}
.team-list {
  width: 648px;
  .team-item {
    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .left-img {
      width: 100%;
      height: 40px;
      margin-bottom: 16px;
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    .right-con {
      font-size: 14px;
      color: #262626;
      margin: 0 32px;
    }
  }
  .active {
    border: 1px solid #1890FF;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
